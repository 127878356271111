import { useEffect } from "react";
import type { ExclusiveEventHintOrCaptureContext } from "@/services/logging/capture-client-exception";
import { captureClientException } from "@/services/logging/capture-client-exception";

export const useCaptureException = (
  exception: unknown,
  hint?: ExclusiveEventHintOrCaptureContext,
) => {
  useEffect(() => {
    captureClientException(exception, hint);
  }, [exception, hint]);
};
